import React from 'react';
import { Admin, fetchUtils, Resource } from 'react-admin';
import authProvider from "./authProvider";
import dataProvider from "./dataProvider";
import PostIcon from '@material-ui/icons/Book';
import { ImageTaskList, ImageTaskCreate } from './ImageTask';
import { VideoTaskList, VideoTaskCreate } from './VideoTask';

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers();
    }
    const token = localStorage.getItem('token');
    options.headers.set('Authorization', `Bearer ${token}`);
    if (options.method !== 'POST') {
        options.headers.set('Accept', 'application/json');
    }
    return fetchUtils.fetchJson(url, options);
};



const apiUrl = 'https://api.pandagibon.xyz';
//const apiUrl = 'http://localhost';
const provider = dataProvider(apiUrl, httpClient);

const App = () => (
    <Admin authProvider={authProvider(apiUrl)} dataProvider={provider}>
        <Resource name="image" list={ImageTaskList} create={ImageTaskCreate(provider.getImageNetClasses())} icon={PostIcon} />
        <Resource name="video" list={VideoTaskList} create={VideoTaskCreate(provider.getImageNetClasses())} icon={PostIcon} />
    </Admin>
);

export default App;
