import React, { Component, Fragment } from 'react';
import {
    List,
    Datagrid,
    TextField,
    ImageField,
    ImageInput,
    Create,
    SimpleForm,
    CreateButton,
    RefreshButton,
    SelectInput,
    FormDataConsumer,
    AutocompleteInput,
    BulkDeleteButton,
    SimpleFormIterator,
    ArrayInput,
    useUnselectAll,
    useNotify,
    useDataProvider, NumberInput, number, minValue, maxValue
} from 'react-admin';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles'
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import * as moment from 'moment';
import {get, set} from 'lodash';
import * as JSZip from 'jszip';
import * as FileSaver from 'file-saver';
import GetAppIcon from '@material-ui/icons/GetApp';
import { fade } from '@material-ui/core/styles/colorManipulator';


const useStyles = makeStyles(
    theme => ({
        image: { width: '200px' },
        downloadButton: {
            color: theme.palette.text.primary,
            '&:hover': {
                backgroundColor: fade(theme.palette.text.primary, 0.12),
                // Reset on mouse devices
                '@media (hover: none)': {
                    backgroundColor: 'transparent',
                },
            },
        }
    })
);

const ImageTaskField = props => {
    const classes = useStyles();
    return <ImageField className={classes.image} {...props} />;
};

const ObjectField = ({ source, record = {} }) => (
    record && record[source]
     ? (
        <ul>
            {Object.keys(record[source] === null ? {} : record[source]).map( key => (
                    <li key={key} >{key}: {record[source][key]}</li>
            ))}
        </ul>
        )
    : null
);

const CustomDateField = ({ source, record = {}}) => {
    if (record && typeof record[source] === 'object') {
        return <div>{moment(record[source]).format('YYYY-MM-DD HH:mm:SS')}</div>;
    }
    return <div> {record[source]} </div>;
};

ObjectField.defaultProps = { addLabel: true };

const ImageActions = ({
         basePath,
         currentSort,
         displayedFilters,
         exporter,
         filters,
         filterValues,
         onUnselectItems,
         resource,
         selectedIds,
         showFilter,
         total
     }) => (
         <Toolbar>
             {filters && React.cloneElement(filters, {
                 resource,
                 showFilter,
                 displayedFilters,
                 filterValues,
                 context: 'button',
             })}
            <RefreshButton />
            <CreateButton basePath={basePath} />
        </Toolbar>
    );


const DownloadManyButton = ({ selectedIds }) => {
    const notify = useNotify();
    const unselectAll = useUnselectAll();
    const dataProvider = useDataProvider();
    const getZip = (() => {
        dataProvider
            .getMany('image', {ids: selectedIds})
            .then( (result) => {
                const zip = new JSZip();

                let promises = [];
                for (let imageData of result.data) {
                    if (imageData && imageData.result_file_url) {
                        ((url, id) => {
                            promises.push(
                                new Promise( (resolve, reject) => {
                                    let result = dataProvider
                                        .getImageBase64(id);

                                    if (!result) {
                                        reject('something went wrong');
                                        return;
                                    }

                                    result
                                        .then( (data) => {
                                            let fileName = url.split('/').pop().replace(/\?.*/i, '');
                                            try {
                                                zip.file(fileName,  data.data.image, {base64: true});
                                                resolve();
                                            } catch (err) {
                                                console.error(err)
                                            }
                                        });
                                })
                            );
                        })(imageData.result_file_url, imageData.id);
                    }
                }

                if (promises.length < result.data.length) {
                    notify('One or more files cannot be downloaded');
                }

                if (promises.length > 0) {
                    Promise.all(promises)
                        .then(
                            () => {
                                zip.generateAsync({type:"blob"})
                                    .then(function(content) {
                                        // see FileSaver.js
                                        FileSaver.saveAs(content, "result.zip");
                                        unselectAll();
                                    });
                            }
                        );
                }
            })

    });

    const classes = useStyles();
    return (
        <Button
            className={classes.downloadButton}
            label="Download selected"
            onClick={getZip}
        >
            <GetAppIcon/>
            <span>Download selected</span>
        </Button>
    );
};

const ImageBulkActionButtons = props => (
    <Fragment>
        <DownloadManyButton {...props} />
        <BulkDeleteButton {...props} />
    </Fragment>
);

class DownloadButton extends Component {
    handleClick = () => {
        const { record } = this.props;

        window.open(record.result_file_url, '_blank')
    }

    render() {
        const { record } = this.props;
        if (!record.result_file_url) {
            return <div></div>;
        }
        return <Button variant="contained" label="Download" onClick={this.handleClick} >Download</Button>;
    }
}

const ImageTaskList = (props) => (
    <List {...props} actions={<ImageActions />} bulkActionButtons={<ImageBulkActionButtons />} >
        <Datagrid>
            <ImageTaskField source="origin_file_url" />
            <TextField source="status" />
            <CustomDateField source="created_at_date" />
            <CustomDateField source="predicted_finished_at" label="Estimated processing finish"/>
            <ObjectField source="result_additional_data" />
            <DownloadButton/>
        </Datagrid>
    </List>
);
const ImageTaskCreate = (classesPromise) => {
    let inputClasses = [{"id": "none", "name": "Random"}];
    let imageNetClasses = [];
    classesPromise
        .then(res => {
            inputClasses = inputClasses.concat(res.data)
            imageNetClasses = imageNetClasses.concat(res.data)
        });
    const expantionPannelClasses = useStyles();
    return (props) => (
    <Create {...props}>
        <SimpleForm>
            <ImageInput source="image" label="" accept="image/*" multiple={true}>
                <div source='src'></div>
            </ImageInput>
            <FormDataConsumer subscription={{ values: true }}>
                {({formData, ...rest}) => {
                    // let record = formData;
                    return <ArrayInput source="image">
                        <SimpleFormIterator source="image" disableAdd={true}>
                            <FormDataConsumer subscription={{ values: true }}>
                                {({formData, getSource, scopedFormData}) => {
                                    getSource();
                                    return  <table>
                                        <tbody>
                                            <tr>
                                                <td width="300">
                                                    <ImageField  source='src' record={scopedFormData} style={{"float": "left"}}/>
                                                </td>
                                                <td>
                                                    <SelectInput
                                                        record={scopedFormData}
                                                        label="Transform type"
                                                        source={getSource('params.type')}
                                                        choices={[{ id: 'imagenet', name: 'ImageNet'},{id : 'random_transform', name: 'Random noise'}]}
                                                        defaultValue={"imagenet"}
                                                    />
                                                    <FormDataConsumer subscription={{ values: true }}>
                                                        {({ formData }) => {
                                                            let params = get(formData, getSource('params'));
                                                            if (params && params['type'] === 'imagenet') {
                                                                return  <div>
                                                                    <AutocompleteInput
                                                                        source={getSource('params.id')}
                                                                        choices={inputClasses}
                                                                        defaultValue={"none"}
                                                                        translateChoice={false}
                                                                        suggestionLimit={20}
                                                                        isRequired={true}
                                                                        label="ImageNet class"
                                                                    />

                                                                </div>
                                                            }

                                                            if (params && params['type'] === 'random_transform') {
                                                                if (!params['count']) {
                                                                    set(formData, getSource('params.count'), 1)
                                                                }
                                                                return <div>
                                                                    <NumberInput source={getSource('params.count')} step={1} label={"Count of copies"} validate={[number(), minValue(1), maxValue(10)]}/>

                                                                </div>;
                                                            }

                                                        }}
                                                    </FormDataConsumer>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                }
                                }
                            </FormDataConsumer>

                        </SimpleFormIterator>
                    </ArrayInput>;
                }

                }
            </FormDataConsumer>
            <ExpansionPanel style={{"width": 100 + "%"}}>
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                >
                    <Typography className={expantionPannelClasses.heading}>ImageNet classes full list</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <ul>
                        {imageNetClasses.map( key => (
                            <li key={key.id} >{key.name}</li>
                        ))}
                    </ul>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </SimpleForm>
    </Create>
)};


export {ImageTaskList, ImageTaskCreate};