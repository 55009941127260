import React, { Component, Fragment } from 'react';
import {
    List,
    Datagrid,
    TextField,
    ImageInput,
    Create,
    SimpleForm,
    CreateButton,
    RefreshButton,
    SelectInput,
    FormDataConsumer,
    BulkDeleteButton,
    SimpleFormIterator,
    ArrayInput,
    useUnselectAll,
    useNotify,
    useDataProvider,
    NumberInput,
    number,
    minValue,
    maxValue
} from 'react-admin';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button';
import * as moment from 'moment';
import {get, set} from 'lodash';
import * as JSZip from 'jszip';
import * as FileSaver from 'file-saver';
import GetAppIcon from '@material-ui/icons/GetApp';
import { fade } from '@material-ui/core/styles/colorManipulator';
import ReactPlayer from "react-player";
import Slider from '@material-ui/core/Slider';

const useStyles = makeStyles(
    theme => ({
        video: { width: '220px' },
        downloadButton: {
            color: theme.palette.text.primary,
            '&:hover': {
                backgroundColor: fade(theme.palette.text.primary, 0.12),
                // Reset on mouse devices
                '@media (hover: none)': {
                    backgroundColor: 'transparent',
                },
            },
        }
    })
);

const PrettoSlider = withStyles({
    root: {
        color: '#525daf',
        height: 8,
    },
    thumb: {
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        marginTop: -8,
        marginLeft: -12,
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 4px)',
    },
    track: {
        height: 8,
        borderRadius: 4,
    },
    rail: {
        height: 8,
        borderRadius: 4,
    },
})(Slider);

const VideoTaskField = ({ source, record = {} }) => {
    const classes = useStyles();
    return <ReactPlayer className={classes.video} url={record[source]} playing={false} width={"220px"}/>;
};

const ObjectField = ({ source, record = {} }) => (
    record && record[source]
     ? (
        <ul>
            {Object.keys(record[source] === null ? {} : record[source]).map( key => (
                    <li key={key} >{key}: {record[source][key]}</li>
            ))}
        </ul>
        )
    : null
);

const CustomDateField = ({ source, record = {}}) => {
    if (record && typeof record[source] === 'object') {
        return <div>{moment(record[source]).format('YYYY-MM-DD HH:mm:SS')}</div>;
    }
    return <div> {record[source]} </div>;
};

ObjectField.defaultProps = { addLabel: true };

const ImageActions = ({
         basePath,
         currentSort,
         displayedFilters,
         exporter,
         filters,
         filterValues,
         onUnselectItems,
         resource,
         selectedIds,
         showFilter,
         total
     }) => (
         <Toolbar>
             {filters && React.cloneElement(filters, {
                 resource,
                 showFilter,
                 displayedFilters,
                 filterValues,
                 context: 'button',
             })}
            <RefreshButton />
            <CreateButton basePath={basePath} />
        </Toolbar>
    );


const DownloadManyButton = ({ selectedIds }) => {
    const notify = useNotify();
    const unselectAll = useUnselectAll();
    const dataProvider = useDataProvider();
    const getZip = (() => {
        dataProvider
            .getMany('video', {ids: selectedIds})
            .then( (result) => {
                const zip = new JSZip();

                let promises = [];
                for (let imageData of result.data) {
                    if (imageData && imageData.result_file_url) {
                        ((url, id) => {
                            promises.push(
                                new Promise( (resolve, reject) => {
                                    let result = dataProvider
                                        .getVideoBase64(id);

                                    if (!result) {
                                        reject('something went wrong');
                                        return;
                                    }

                                    result
                                        .then( (data) => {
                                            let fileName = url.split('/').pop().replace(/\?.*/i, '');
                                            try {
                                                zip.file(fileName,  data.data.video, {base64: true});
                                                resolve();
                                            } catch (err) {
                                                console.error(err)
                                            }
                                        });
                                })
                            );
                        })(imageData.result_file_url, imageData.id);
                    }
                }

                if (promises.length < result.data.length) {
                    notify('One or more files cannot be downloaded');
                }

                if (promises.length > 0) {
                    Promise.all(promises)
                        .then(
                            () => {
                                zip.generateAsync({type:"blob"})
                                    .then(function(content) {
                                        // see FileSaver.js
                                        FileSaver.saveAs(content, "result.zip");
                                        unselectAll();
                                    });
                            }
                        );
                }
            })

    });

    const classes = useStyles();
    return (
        <Button
            className={classes.downloadButton}
            label="Download selected"
            onClick={getZip}
        >
            <GetAppIcon/>
            <span>Download selected</span>
        </Button>
    );
};

const ImageBulkActionButtons = props => (
    <Fragment>
        <DownloadManyButton {...props} />
        <BulkDeleteButton {...props} />
    </Fragment>
);

class DownloadButton extends Component {
    handleClick = () => {
        const { record } = this.props;

        window.open(record.result_file_url, '_blank')
    };

    render() {
        const { record } = this.props;
        if (!record.result_file_url) {
            return <div></div>;
        }
        return <Button variant="contained" label="Download" onClick={this.handleClick} >Download</Button>;
    }
}

const VideoTaskList = (props) => (
    <List {...props} actions={<ImageActions />} bulkActionButtons={<ImageBulkActionButtons />} >
        <Datagrid>
            <VideoTaskField source="origin_file_url" />
            <TextField source="status" />
            <CustomDateField source="created_at_date" />
            <CustomDateField source="predicted_finished_at" label="Estimated processing finish"/>
            <ObjectField source="result_additional_data" />
            <DownloadButton/>
        </Datagrid>
    </List>
);
const VideoTaskCreate = () => {
    const expantionPannelClasses = useStyles();
    return (props) => (
    <Create {...props}>
        <SimpleForm>
            <ImageInput source="video" label="" accept="video/*" multiple={true}>
                <div source='src'></div>
            </ImageInput>
            <FormDataConsumer subscription={{ values: true }}>
                {({formData, ...rest}) => {
                    // let record = formData;
                    return <ArrayInput source="video">
                        <SimpleFormIterator source="video" disableAdd={true}>
                            <FormDataConsumer subscription={{ values: true }}>
                                {({formData, getSource, scopedFormData}) => {
                                    getSource();
                                    return  <table>
                                        <tbody>
                                            <tr>
                                                <td width="300">
                                                    <VideoTaskField source='src' record={scopedFormData} style={{"float": "left"}}/>
                                                </td>
                                                <td>
                                                    <SelectInput
                                                        record={scopedFormData}
                                                        label="Transform type"
                                                        source={getSource('params.type')}
                                                        choices={[{ id: 'video_ucf-101', name: 'Pregenerated noise'}, {id : 'random_transform', name: 'Random noise'}]}
                                                        defaultValue={"video_ucf-101"}
                                                    />
                                                    <FormDataConsumer subscription={{ values: true }}>
                                                        {({ formData }) => {
                                                            let params = get(formData, getSource('params'));
                                                            if (params && params['type'] === 'video_ucf-101') {
                                                                if (!params['visibility']) {
                                                                    set(formData, getSource('params.visibility'), 20)
                                                                }
                                                                return <div>
                                                                    <span>Overlay visibility (%):</span>
                                                                    <PrettoSlider
                                                                        valueLabelDisplay="on"
                                                                        min={1}
                                                                        max={100}
                                                                        onChange={(event, value) => {set(formData, getSource('params.visibility'), value)}}
                                                                        defaultValue={20}
                                                                        style={{"marginTop": "35px"}}
                                                                    />

                                                                </div>;
                                                            }
                                                            if (params && params['type'] === 'random_transform') {
                                                                if (!params['count']) {
                                                                    set(formData, getSource('params.count'), 1)
                                                                }

                                                                return <div>
                                                                    <NumberInput source={getSource('params.count')} label={"Count of copies"} step={1} validate={[number(), minValue(1), maxValue(10)]}/>

                                                                </div>;
                                                            }
                                                        }}
                                                    </FormDataConsumer>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                }
                                }
                            </FormDataConsumer>

                        </SimpleFormIterator>
                    </ArrayInput>;
                }

                }
            </FormDataConsumer>
        </SimpleForm>
    </Create>
)};


export {VideoTaskList, VideoTaskCreate};