import { stringify } from 'query-string';
import { fetchUtils, DataProvider } from 'ra-core';
/**
 * Maps react-admin queries to a simple REST API
 *
 * This REST dialect is similar to the one of FakeRest
 *
 * @see https://github.com/marmelab/FakeRest
 *
 * @example
 *
 * getList     => GET http://my.api.url/posts?sort=['title','ASC']&range=[0, 24]
 * getOne      => GET http://my.api.url/posts/123
 * getMany     => GET http://my.api.url/posts?filter={id:[123,456,789]}
 * update      => PUT http://my.api.url/posts/123
 * create      => POST http://my.api.url/posts
 * delete      => DELETE http://my.api.url/posts/123
 *
 * @example
 *
 * import React from 'react';
 * import { Admin, Resource } from 'react-admin';
 * import simpleRestProvider from 'ra-data-simple-rest';
 *
 * import { PostList } from './posts';
 *
 * const App = () => (
 *     <Admin dataProvider={simpleRestProvider('http://path.to.my.api/')}>
 *         <Resource name="posts" list={PostList} />
 *     </Admin>
 * );
 *
 * export default App;
 */
export default (apiUrl, httpClient = fetchUtils.fetchJson): DataProvider => ({
    getList: (resource, params) => {
        const { page, perPage } = params.pagination;

        let offset = perPage * (page - 1);
        if (offset < 0) {
            offset = 0
        }

        let query = {
            limit: perPage,
            offset: offset,
        };

        const { field, order } = params.sort;

        if (field) {
            query['sort'] = field;
            if (order === 'ASC' || order === 'DESC') {
                query['order'] = order
            } else {
                query['order'] = 'DESC'
            }
        }

        const url = `${apiUrl}/api/v1/task/${resource}/list?${stringify(query)}`;

        return httpClient(url).then(({ headers, json }) => {
            for (let i = 0; i < json.data.items.length; ++i) {
                json.data.items[i]['created_at_date'] = new Date(json.data.items[i]['created_at']* 1000)
                if (Number(json.data.items[i]['predicted_finished_at']) > 0) {
                    json.data.items[i]['predicted_finished_at'] = new Date(json.data.items[i]['predicted_finished_at'] * 1000)
                }
            }
            return {
                data: json.data.items,
                total: json.data.total
            };
        });
    },

    getMany: (resource, params) => {
        const query = {
            'ids[]': params.ids,
        };
        const url = `${apiUrl}/api/v1/task/${resource}/selected?${stringify(query)}`;
        return httpClient(url).then(({ headers, json }) => {
            let rows = [];
            for (let row of json.data) {
                row['created_at_date'] = new Date(row['created_at'] * 1000)
                if (Number(row['predicted_finished_at']) > 0) {
                    row['predicted_finished_at'] = new Date(row['predicted_finished_at'] * 1000)
                }
                rows.push(row)
            }

            return {
                "data": rows
            };
        });
    },

    create: (resource, params) => {
        return Promise.all(
            params.data[resource].map(
                (item) => {
                    const reader = new FileReader();
                    let test = new FormData();
                    test.append(resource, item.rawFile);
                    for (let key of Object.keys(item.params)) {
                        test.append('params[' + key + ']', item.params[key]);
                    }
                    reader.readAsDataURL(item.rawFile);
                    return httpClient(`${apiUrl}/api/v1/task/${resource}/create`, {
                        method: 'POST',
                        body: test
                    }).then(({json}) => ({
                        data: json.data,
                    }))
                }
            )
        )
        .then(
            (responses) => {
                let result = {};
                for (let response  of responses) {
                    result = response
                }
                return {
                    data: result
                };
            }
        );
    },

    delete: (resource, params) =>
        httpClient(`${apiUrl}/api/v1/task/${resource}/delete/${params.id}`, {
            method: 'DELETE',
        }).then(({ json }) => ({ data: json })),

    // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
    deleteMany: (resource, params) =>
        Promise.all(
            params.ids.map(id =>
                httpClient(`${apiUrl}/api/v1/task/${resource}/delete/${id}`, {
                    method: 'DELETE',
                })
            )
        ).then(responses => ({ data: responses.map(({ json }) => json.id) })),

    getImageNetClasses: () =>
        httpClient(`${apiUrl}/api/v1/task/image/imagenet/classes`, {
            method: 'GET',
        }).then(({json}) => ({data: json.data})),

    getImageBase64: (id) =>
        httpClient(`${apiUrl}/api/v1/task/image/result_content/${id}`, {
            method: 'GET',
        }).then(({json}) => ({data: json.data})),
    getVideoBase64: (id) =>
        httpClient(`${apiUrl}/api/v1/task/video/result_content/${id}`, {
            method: 'GET',
        }).then(({json}) => ({data: json.data})),

});